import React, { Component } from "react"
import styled from "styled-components"
import { loginUrl } from "../../../config/keys"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
class BoxFour extends Component {
  render() {
    return (
      <MainBox>
        <Box>
          <BoxHeader>Close more Opportunities</BoxHeader>
          <BoxContent>
            Whether you’re an enterprise with global footprint or a startup
            readying for your journey, we’ve got you covered.
          </BoxContent>
          <BoxButton>
            <TrialLink1
              onClick={(e) => {
                // To stop the page reloading
                // e.preventDefault()
                // Lets track that custom click
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category: "start your free trial  button in navbar ",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: "Start your free trial button in navbar",
                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  value: 43,
                })
                //... Other logic here
              }}
              href={`${loginUrl}/register/Option`}
              target="_blank"
            >
              Start My Free Trial
            </TrialLink1>
          </BoxButton>
          <SubContent>
            {" "}
            Free 30 days trial for all subscriptions. Full access. No credit
            card required.
          </SubContent>
        </Box>
      </MainBox>
    )
  }
}
export default BoxFour

const MainBox = styled.div`
  background: #2a1e5c;
  display: grid;
  justify-content: center;
  align-content: center;
  height: 370px;
`
const Box = styled.div`
  text-align: center;
  height: 50vh;
  width: 50vw;
  @media screen and (max-width: 600px) {
    height: auto;
    width: auto;
  }
`

const BoxHeader = styled.h2`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 3em;
  line-height: 2.125em;
  letter-spacing: -0.5px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #ffffff;
`
const BoxContent = styled.p`
  font-family: "Roboto";
  font-size: 1.125em;
  font-weight: 100;
  letter-spacing: -0.5px;
  text-align: center;
  color: #ffffff;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`

const BoxButton = styled.div`
  padding: 20px 1px;
`
const TrialLink1 = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;
  background-color: #29c8ff;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-size: 1.075em;
  /* width: 148px;
  height: 58px; */
  color: #ffffff !important;
`
const SubContent = styled.p`
  font-family: "Roboto";
  font-size: 1.125em;
  font-weight: 100;
  letter-spacing: -0.5px;
  text-align: center;
  /* margin-top: 40px; */
  color: #ffffff;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
