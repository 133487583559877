import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Collboration from "../modules/SubFetaures/Collboration"

const collboration = () => (
  <Layout>
    <SEO title="Collaboration- Internal & Alliance Partners" 
    description="Include users from non-sales functions to accelerate opportunity closures. 
    Also collaborate with your partners by extending access to Korero and share data securely."/>
    <Collboration />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default collboration
