import React from "react"
import BoxFour from "./CollaborationNewPage/BoxFour"
import BoxOne from "./CollaborationNewPage/BoxOne"
import BoxThree from "./CollaborationNewPage/BoxThree"
function Collaboration() {
  return (
    <div>
      <BoxOne />
      <BoxThree />
      <BoxFour />
    </div>
  )
}

export default Collaboration
